import React from 'react';
import { Container, Typography } from '@material-ui/core';
import { useStyles } from './styles';

const Questions: React.FC = () => {
  const classes = useStyles();

  const schemaFaq = {
    '@context': 'https://schema.org',
    '@type': 'FAQPage',
    mainEntity: [
      {
        '@type': 'Question',
        name: 'How do I find a good solar installer in New Jersey?',
        acceptedAnswer: {
          '@type': 'Answer',
          text:
            "The best way to find a good solar installer in New Jersey is to ask for referrals from friends, family, or neighbors who have already installed solar, like our NJ solar customer Ryan G.— \"I think the app is great and was helpful listing our options. I put together a package and I must admit it is more affordable than I thought\".\n\nHere's why Solvana is the best solar installer in New Jersey:\n\n    Up to 30% lower up-front cost— Solvana charges less than our competitors because we have almost zero overhead, a digital process to replace salespeople, and no sales commissions.\n    We offer the best equipment— All solar companies offer the same equipment from the same distributors, but we offer them at a better price with the highest installation standards. Ask us to find out more!\n    Expertise from 5 decades of industry experience— Solvana has 50-years of experience in construction and custom home improvements. We have been in the solar business since 2008 and have the expert knowledge to maximize cost savings for you while maintaining the kind of quality installation you expect.\n\nHere at Solvana, you'll find the resources you need to make the switch to solar energy with confidence and at your own speed. And, as your local solar company, we'll be there for you every step of the way. To begin the process, fill out the contact form on this page.",
        },
      },
      {
        '@type': 'Question',
        name: 'Is it worth going solar in NJ?',
        acceptedAnswer: {
          '@type': 'Answer',
          text:
            "New Jersey solar is a fantastic investment in many cases. However, if you live in an area with limited sunshine or if you don't use a lot of energy, solar may not be worth it. Whatever the situation, Solvana will give you an honest cost-benefit assessment of installing solar in your home.",
        },
      },
      {
        '@type': 'Question',
        name: 'How much does it cost to install solar in NJ?',
        acceptedAnswer: {
          '@type': 'Answer',
          text:
            'The cost of New Jersey solar will vary based on a number of factors, including the components you select for your system. Your prices will rise if you install a solar battery or high-efficiency panels. Solar generating stations, on the other hand, range in price from $15,000 to $25,000 on average (before credits and other incentives). Solvana shows you options. You get to decide what meets your needs.',
        },
      },
      {
        '@type': 'Question',
        name: 'Does NJ have a solar tax credit?',
        acceptedAnswer: {
          '@type': 'Answer',
          text:
            'Yes, NJ solar incentives include tax credits, renewable energy credits, and net metering as options for offsetting these costs.',
        },
      },
      {
        '@type': 'Question',
        name: 'Can I get free solar panels in NJ?',
        acceptedAnswer: {
          '@type': 'Answer',
          text:
            'NO, nothing is FREE! The "FREE" solar panels end up costing many homeowners in the long run and the third-party owner takes all of the benefits. The third party takes all of the 30% federal tax credit, and ALL State SRECs, which combined can be worth more than $15,000 on average. HOWEVER, when you buy solar panels in NJ the systems always have an option to be Cash Flow Positive, meaning at the end of the day you will pay less for solar than you would have without solar panels. How can that be? In short, the Federal tax credit, and SRECs are so valuable that they help pay for your monthly solar payments. You can see how the incentives pay as much as 50% of the monthly solar payments because our loan partners offer 20-year loans at 0.49% APR.',
        },
      },
      {
        '@type': 'Question',
        name: 'Can I lease solar panels in New Jersey?',
        acceptedAnswer: {
          '@type': 'Answer',
          text:
            'When selecting whether to lease your NJ solar panels, it is critical to evaluate your terms. With NJ solar leasing, you normally make monthly payments for 20 or 25-years, depending on your solar power lease. If your lease expires, you may generally extend it or make all the lease payments if you want to own your panels. There is typically no option to own it after making all the lease payments. That’s why we prefer loans. When the loan payments end, you own it',
        },
      },
      {
        '@type': 'Question',
        name: 'Does solar energy work in New Jersey?',
        acceptedAnswer: {
          '@type': 'Answer',
          text:
            "New Jersey is one of the country's solar powerhouses, being one of just a few states with 3 GW worth of installed capacity from over 148,000 individual installations.",
        },
      },
    ],
  };

  return (
    <>
      <Container component="section" maxWidth="lg" className={`${classes.root} ${classes.headerContainer}`}>
        <Typography variant="h2">Questions About Solar Energy in New Jersey?</Typography>
      </Container>
      <Container component="section" maxWidth="lg" className={classes.root}>
        <div className={classes.leftContainer}>
          <Typography variant="h3">How do I find a good solar installer in New Jersey?</Typography>
          <Typography variant="body1">
            The best way to find a good solar installer in New Jersey is to ask for referrals from friends, family, or
            neighbors who have already installed solar, like our NJ solar customer Ryan G.— &quot;I think the app is
            great and was helpful listing our options. I put together a package and I must admit it is more affordable
            than I thought&quot;.
          </Typography>
          <Typography variant="body1">Here&apos;s why Solvana is the best solar installer in New Jersey:</Typography>
          <Typography component="div" variant="body1">
            <ul>
              <li>
                Up to 30% lower up-front cost— Solvana charges less than our competitors because we have almost zero
                overhead, a digital process to replace salespeople, and no sales commissions.
              </li>
              <li>
                We offer the best equipment— All solar companies offer the same equipment from the same distributors,
                but we offer them at a better price with the highest installation standards. Ask us to find out more!
              </li>
              <li>
                Expertise from 5 decades of industry experience— Solvana has 50-years of experience in construction and
                custom home improvements. We have been in the solar business since 2008 and have the expert knowledge to
                maximize cost savings for you while maintaining the kind of quality installation you expect.
              </li>
            </ul>
          </Typography>
          <Typography variant="body1">
            Here at Solvana, you&apos;ll find the resources you need to make the switch to solar energy with confidence
            and at your own speed. And, as your local solar company, we&apos;ll be there for you every step of the way.
            To begin the process, fill out the contact form on this page.
          </Typography>
          <Typography variant="h3">Is it worth going solar in NJ?</Typography>
          <Typography variant="body1">
            New Jersey solar is a fantastic investment in many cases. However, if you live in an area with limited
            sunshine or if you don&apos;t use a lot of energy, solar may not be worth it. Whatever the situation,
            Solvana will give you an honest cost-benefit assessment of installing solar in your home.
          </Typography>
          <Typography variant="h3">How much does it cost to install solar in NJ?</Typography>
          <Typography variant="body1">
            The cost of New Jersey solar will vary based on a number of factors, including the components you select for
            your system. Your prices will rise if you install a solar battery or high-efficiency panels. Solar
            generating stations, on the other hand, range in price from $15,000 to $25,000 on average (before credits
            and other incentives). Solvana shows you options. You get to decide what meets your needs.
          </Typography>
        </div>
        <div className={classes.rightContainer}>
          <Typography variant="h3">Does NJ have a solar tax credit?</Typography>
          <Typography variant="body1">
            Yes, NJ solar incentives include tax credits, renewable energy credits, and net metering as options for
            offsetting these costs.
          </Typography>
          <Typography variant="h3">Can I get free solar panels in NJ?</Typography>
          <Typography variant="body1">
            NO, nothing is FREE! The &quot;FREE&quot; solar panels end up costing many homeowners in the long run and
            the third-party owner takes all of the benefits. The third party takes all of the 30% federal tax credit,
            and ALL State SRECs, which combined can be worth more than $15,000 on average. HOWEVER, when you buy solar
            panels in NJ the systems always have an option to be Cash Flow Positive, meaning at the end of the day you
            will pay less for solar than you would have without solar panels. How can that be? In short, the Federal tax
            credit, and SRECs are so valuable that they help pay for your monthly solar payments. You can see how the
            incentives pay as much as 50% of the monthly solar payments because our loan partners offer 20-year loans at
            0.49% APR.
          </Typography>
          <Typography variant="h3">Can I lease solar panels in New Jersey?</Typography>
          <Typography variant="body1">
            When selecting whether to lease your NJ solar panels, it is critical to evaluate your terms. With NJ solar
            leasing, you normally make monthly payments for 20 or 25-years, depending on your solar power lease. If your
            lease expires, you may generally extend it or make all the lease payments if you want to own your panels.
            There is typically no option to own it after making all the lease payments. That’s why we prefer loans. When
            the loan payments end, you own it
          </Typography>
          <Typography variant="h3">Does solar energy work in New Jersey?</Typography>
          <Typography variant="body1">
            New Jersey is one of the country&apos;s solar powerhouses, being one of just a few states with 3 GW worth of
            installed capacity from over 148,000 individual installations.
          </Typography>
        </div>
        <script type="application/ld+json">{JSON.stringify(schemaFaq)}</script>
      </Container>
    </>
  );
};

export default Questions;
