import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import Img from 'gatsby-image';
import { Container, Typography } from '@material-ui/core';
import { useStyles } from './styles';

const StartSavingWithSolar: React.FC = () => {
  const classes = useStyles();

  const data = useStaticQuery(graphql`
    query {
      image: file(relativePath: { eq: "new-jersey-solar-panel-installers-3.png" }) {
        childImageSharp {
          fluid(maxWidth: 1200) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      image2: file(relativePath: { eq: "new-jersey-solar-panel-installers-1.png" }) {
        childImageSharp {
          fluid(maxWidth: 1200) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `);

  return (
    <>
      <Container component="section" maxWidth="lg" className={`${classes.root} ${classes.headerContainer}`}>
        <Typography variant="h2">Start Saving with Solar</Typography>
      </Container>
      <Container component="section" maxWidth="lg" className={classes.root}>
        <div className={classes.leftContainer}>
          <Typography variant="body1">
            You won&apos;t have to rely on the public utility grid for electricity if you generate your own. As a bonus,
            if you have a solar battery to store your energy, you will not be inconvenienced or injured by power
            outages, blackouts, or brownouts. Depending on your utility&pos;s billing practices, you may also benefit by
            not having to worry about variable energy costs caused by varying demand, or your utility&apos;s increasing
            costs to make your electricity. Solar power&apos;s lower energy costs are a big selling feature. Solar
            panels allow you to create your own power rather than purchasing it from a utility or other energy supplier.
            As a result, you are no longer required to make large monthly payments for your energy use, unless you have
            financed monthly.
          </Typography>
          <Typography variant="body1">
            Going solar is also a terrific way to help the environment. By shifting from fossil fuels to renewable
            energy, you will lower your carbon footprint and reduce your environmental impact.
          </Typography>
          <Typography variant="body1">
            After claiming the federal solar tax credit of 30%, the median cost of an installed household solar
            installation in New Jersey is presently $12,343. This works out to $2.78/Watt for cash purchases. The cost
            of solar installations vary across states. The figures below depict the median cost of rooftop New Jersey
            solar installations.
          </Typography>
          <Typography variant="body1">
            <Img Tag="span" fluid={data.image2.childImageSharp.fluid} />
            <Typography variant="caption">
              Source: &nbsp;
              <a href="https://www.solarreviews.com/solar-panels/new-jersey">
                https://www.solarreviews.com/solar-panels/new-jersey
              </a>
            </Typography>
          </Typography>
        </div>
        <div className={classes.rightContainer}>
          <Img Tag="span" fluid={data.image.childImageSharp.fluid} />
          <Typography variant="body1">
            Over the previous ten years, the prices of solar panels have dropped by over 80%. Solar panels may also be
            eligible for the federal tax credit of 30% and homeowners can sell energy back to their utility under the
            net metering laws, which makes solar an attractive investment. Solar provides returns that are far higher
            than long-term median returns from both the stock market and real estate investments.
          </Typography>
          <Typography variant="body1">
            Some other ways of looking at energy costs are the levelized price to be paid for each kilowatt-hour of
            power used within the next 25 years with and without solar panels. As shown in the table below, the savings
            are substantial.
          </Typography>
          <Typography variant="body1">
            The selection of components will have a significant impact on the total price of your solar generating
            station. Choosing high-end equipment, such as the most efficient solar panels and the greatest solar
            batteries on the market, would, as predicted, increase expenses. Choosing the most basic equipment, on the
            other hand, will keep the costs modest. Anyone establishing a solar energy system must consider the benefits
            and drawbacks of superior equipment vs. affordability by analyzing the value of each of your equipment and
            financing options This is where Solvana has a distinct advantage over others. Solvana&apos;s Go Solar App is
            specifically designed to show you all your options and facilitate the comparison.
          </Typography>
        </div>
      </Container>
    </>
  );
};

export default StartSavingWithSolar;
