import React from 'react';
import Hero from './Hero';
import InstallationProcess from './InstallationProcess';
import Content from './Content';

const NJSolarEnergy: React.FC = () => {
  return (
    <>
      <Hero />
      <InstallationProcess />
      <Content />
    </>
  );
};

export default NJSolarEnergy;
