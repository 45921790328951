import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import Img from 'gatsby-image';
import { Container, Typography } from '@material-ui/core';
import { useStyles } from './styles';

const SolarIncentivesAndTaxCredits: React.FC = () => {
  const classes = useStyles();

  const data = useStaticQuery(graphql`
    query {
      image: file(relativePath: { eq: "new-jersey-solar-panel-installers-2.png" }) {
        childImageSharp {
          fluid(maxWidth: 1200) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `);

  return (
    <>
      <Container component="section" maxWidth="lg" className={`${classes.root} ${classes.headerContainer}`}>
        <Typography variant="h2">New Jersey Solar Incentives and Tax Credits</Typography>
      </Container>
      <Container component="section" maxWidth="lg" className={classes.root}>
        <div className={classes.leftContainer}>
          <Typography variant="body1">
            The effectiveness of New Jersey’s Clean Energy Solar Program is based on two fundamental programs where one
            rewards you for the energy you create, while another compensates you for the clean features of that energy.
          </Typography>
          <Typography variant="h3">SuSI (Successor Solar Incentive)</Typography>
          <Typography variant="body1">
            You can earn &nbsp;
            <a href="https://njcleanenergy.com/renewable-energy/programs/susi-program">one SuSI</a>, or SREC-II, per
            megawatt-hour (MWh) that the solar panel system produces under the SuSI, or SREC-II, program. The worth of
            an SREC-II differs based on what type of system is installed; for residential rooftop applications, you
            receive a fixed amount of $90 for each SREC-II you create throughout 15-years. That means that if you
            construct an 8-kW system that generates 10 MWh annually, you might earn an additional $900 each year by
            selling your SREC-II SuSI certificates for a windfall of $13,500.
          </Typography>
          <Typography variant="h3">Net Metering in New Jersey</Typography>
          <Typography variant="body1">
            This policy permits you to resell excess power to the grid at retail rates. This is fantastic since the
            retail cost for&nbsp;
            <a href="https://www.njcleanenergy.com/renewable-energy/programs/net-metering-and-interconnection">
              NJ net metering
            </a>
            &nbsp;is substantially higher than the wholesale rate the utilities offer you. Furthermore, the option to
            buy and sell electricity from the grid allows you to balance your usage and production rather than wasting
            any extra power you generate.
          </Typography>
          <Typography variant="h3">The Federal New Jersey Solar Tax Credit</Typography>
          <Typography variant="body1">
            Don&apos;t overlook federal NJ solar incentives! The&nbsp;
            <a href="https://www.energystar.gov/about/federal_tax_credits">Investment Tax Credit</a>
            &nbsp;(ITC) can cut the price of the PV solar power installation by as much as 30%. Remember that the ITC
            only extends to individuals who fully acquire their solar energy generating station (with either a solar
            loan or with cash). To be eligible for the ITC you must pay the amount of the ITC in income taxes, which can
            be spread out over a number of years.
          </Typography>
        </div>
        <div className={classes.rightContainer}>
          <Typography variant="h3">Solar Sales Tax Exemption</Typography>
          <Typography variant="body1">
            Due to the&nbsp;
            <a href="https://www.state.nj.us/treasury/taxation/pdf/other_forms/sales/st4.pdf">
              PV Energy Sales Tax Exemption
            </a>
            , you will be exempt from paying any NJ State Sales Tax when you purchase your own solar energy station.
            That means your cost for solar is 7% less.
          </Typography>
          <Typography variant="h3">Exemption from Property Taxes</Typography>
          <Typography variant="body1">
            Similarly, because of the renewable energy property tax incentive, New Jersey residents who invest in a
            solar energy system will not have to pay any extra property taxes on the increased value that their solar
            generating station brings to their homes.
          </Typography>
          <Typography variant="body1">
            <Img Tag="span" fluid={data.image.childImageSharp.fluid} />
            <Typography variant="caption">
              Source:&nbsp;
              <a href="https://www.solarreviews.com/solar-panels/new-jersey">
                https://www.solarreviews.com/solar-panels/new-jersey
              </a>
            </Typography>
          </Typography>
          <Typography variant="body1">
            Here at Solvana, you&apos;ll find the resources you need to make the switch to solar energy with confidence
            and at your own speed. And, as your Solar Advocates, we&apos;ll be there for you every step of the way.
            Solvana&apos;s process is smart, fast and easy. Check out the Go Solar App and make a proposal. You&apos;ll
            be glad you did.
          </Typography>
          <Typography variant="body1">
            Using our Solar Praxeum Training, we&apos;ll walk you through the entire process and help you avoid the
            typical pitfalls while you migrate to solar.
          </Typography>
          <Typography variant="body1">
            Your desire for a more sustainable energy source for your home makes it our duty to help you get solar as
            easily as possible. That means you can go solar without having to deal with bothersome salesmen, hidden fees
            in the tiny print, or being misled by misrepresented overinflated solar benefits.
          </Typography>
        </div>
      </Container>
    </>
  );
};

export default SolarIncentivesAndTaxCredits;
