import { createStyles, Theme } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { linearPrimaryGradient } from '../../../theme/Colors';

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      padding: theme.spacing(0, 3, 8),
      [theme.breakpoints.down('sm')]: {
        flexDirection: 'column',
        padding: theme.spacing(0, 2),
      },
    },
    leftContainer: {
      width: '50%',
      padding: theme.spacing('3%', 0, 0, '5%'),
      zIndex: 2,
      '& p': {
        margin: theme.spacing(3, 0, 0),
        paddingRight: theme.spacing(8),
        [theme.breakpoints.down('md')]: {
          padding: 0,
        },
      },
      [theme.breakpoints.down('sm')]: {
        width: '100%',
        alignItems: 'center',
        textAlign: 'center',
        marginBottom: theme.spacing(3),
        padding: theme.spacing(3),
      },
    },
    innerContainer: {
      paddingRight: theme.spacing(3),
      '& h1': {
        width: '750px',
        fontSize: '76px',
        lineHeight: '76px',
        [theme.breakpoints.down('md')]: {
          fontSize: '60px',
          lineHeight: '60px',
          width: '100%',
        },
        [theme.breakpoints.down('xs')]: {
          fontSize: '50px',
          lineHeight: '50px',
        },
        background: linearPrimaryGradient,
        '-webkit-background-clip': 'text',
        '-webkit-text-fill-color': 'transparent',
      },
      '& h2': {
        fontSize: '38px',
        lineHeight: '38px',
      },
      [theme.breakpoints.down('sm')]: {
        maxWidth: '600px',
        margin: '0 auto',
      },
    },
    buttonContainer: {
      width: '100%',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      height: '7vh',
    },
    getStartedButton: {
      marginTop: theme.spacing(3),
      maxWidth: '280px',
      color: '#fff',
      [theme.breakpoints.down('md')]: {
        maxWidth: '220px',
      },
    },
    rightContainer: {
      width: '50%',
      padding: theme.spacing(14, 0, 0),
      '& img': {
        padding: 0,
      },
      '& .gatsby-image-wrapper': {
        height: '100%',
        minHeight: '478px',
      },
      [theme.breakpoints.down('sm')]: {
        width: '100%',
        padding: 0,
        '& img': {
          marginTop: 0,
        },
      },
    },
    subtitle: {
      color: theme.palette.secondary.main,
      margin: theme.spacing(3, 0, 6),
      maxWidth: '525px',
    },
    button: {
      maxWidth: '300px',
      textTransform: 'unset',
    },
  }),
);
