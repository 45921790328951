import React from 'react';
import { useStaticQuery, graphql, Link } from 'gatsby';
import { useStyles } from './styles';
import BackgroundImage from 'gatsby-background-image';
import { Container, Typography, useMediaQuery, useTheme } from '@material-ui/core';
import Img from 'gatsby-image';
import { Routes } from '../../../utils/Routes';

const InstallationProcess: React.FC = () => {
  const classes = useStyles();
  const theme = useTheme();
  const isSmallerThanMediumView = useMediaQuery(theme.breakpoints.down('md'));

  const data = useStaticQuery(graphql`
    query {
      image: file(relativePath: { eq: "solvana-lg-battery-solar-roof-1.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1200) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `);

  const imageData = data.image.childImageSharp.fluid;

  if (isSmallerThanMediumView) {
    return (
      <section className={classes.root}>
        <Container maxWidth="lg">
          <div className={classes.smallViewImageContainer}>
            <Img Tag="span" fluid={data.image.childImageSharp.fluid} />
          </div>
          <div className={classes.copyContainer}>
            <Typography variant="body1">
              According to the most recent &nbsp;
              <a href="https://www.nj.gov/dep/aqes/opea-solar.html">Solar Energy Industries Association</a>
              &nbsp; data, more than 308,000 New Jersey households are already powered by solar energy, and solar
              industry analysts predict significant growth in NJ solar in the near future.
            </Typography>
            <Typography variant="body1">
              For years, New Jersey&apos;s solar business has been thriving and employing thousands of people. In New
              Jersey, there are now around 480 solar firms, including contractors, manufacturers, distributors,
              installers, and companies involved in other solar-related operations.&nbsp;
              <a href="https://www.njspotlightnews.org/2017/02/17-02-07-nj-s-solar-stumble-sector-sheds-1-000-jobs-in-past-year/">
                More than 6,056 people
              </a>
              &nbsp; are employed in New Jersey&apos;s thriving solar industry.
            </Typography>
            <Typography variant="body1">
              By using pure solar energy to power your house, you can save money, take advantage of government incentive
              programs, lock in cheap energy rates, and increase the value of your home – all while living a more
              sustainable lifestyle!
            </Typography>
            <Typography variant="body1">
              However, with hundreds of New Jersey solar panel installers to choose from, selecting the best solar
              installation options for your project may be tough.
            </Typography>
            <Typography variant="body1">
              <Link to={Routes.ContactUs}>Let Solvana assist you</Link>
              &nbsp; in reaping the benefits of household solar and battery backup energy systems.
            </Typography>
          </div>
        </Container>
      </section>
    );
  }

  return (
    <section className={classes.root}>
      <BackgroundImage Tag="section" className={classes.backgroundImage} fluid={imageData}>
        <Container maxWidth="lg">
          <div className={classes.copyContainer}>
            <Typography variant="body1">
              According to the most recent&nbsp;
              <a href="https://www.nj.gov/dep/aqes/opea-solar.html">Solar Energy Industries Association</a>
              &nbsp; data, more than 308,000 New Jersey households are already powered by solar energy, and solar
              industry analysts predict significant growth in NJ solar in the near future.
            </Typography>
            <Typography variant="body1">
              For years, New Jersey&apos;s solar business has been thriving and employing thousands of people. In New
              Jersey, there are now around 480 solar firms, including contractors, manufacturers, distributors,
              installers, and companies involved in other solar-related operations. &nbsp;
              <a href="https://www.seia.org/state-solar-policy/new-jersey-solar">5,384 solar jobs</a>
              &nbsp; currently exist in New Jersey&apos;s thriving solar industry.
            </Typography>
            <Typography variant="body1">
              By using pure solar energy to power your house, you can save money, take advantage of government incentive
              programs, lock in cheap energy rates, and increase the value of your home – all while living a more
              sustainable lifestyle!
            </Typography>
            <Typography variant="body1">
              However, with hundreds of New Jersey solar panel installers to choose from, selecting the best solar
              installation options for your project may be tough.
            </Typography>
            <Typography variant="body1">
              <Link to={Routes.ContactUs}>Let Solvana assist you</Link>
              &nbsp; in reaping the benefits of household solar and battery backup energy systems.
            </Typography>
          </div>
        </Container>
      </BackgroundImage>
    </section>
  );
};

export default InstallationProcess;
