import React from 'react';
import Layout from '../containers/Layout';
import NJSolarEnergy from '../containers/NJSolarEnergy';

const TermsAndConditionsPage: React.FC = () => (
  <Layout title="NJ Solar Energy | New Jersey Solar Panel Installers | Solvana">
    <NJSolarEnergy />
  </Layout>
);

export default TermsAndConditionsPage;
