import { createStyles, Theme } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Colors } from '../../../theme/Colors';

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      alignItems: 'center',
      flexDirection: 'column',
      padding: theme.spacing(3),
      textAlign: 'center',
      [theme.breakpoints.down('md')]: {
        padding: theme.spacing(8, 3, 6),
      },
    },
    copyContainer: {
      width: '100%',
      marginBottom: theme.spacing(9),
      [theme.breakpoints.down('md')]: {
        marginBottom: theme.spacing(5),
      },
      '& h2': {
        maxWidth: '720px',
        margin: theme.spacing(4, 'auto', 0),
        color: theme.palette.success.main,
        lineHeight: '60px',
        [theme.breakpoints.down('sm')]: {
          lineHeight: '46px',
        },
      },
      '& h3': {
        maxWidth: '720px',
        margin: theme.spacing(4, 'auto', 0),
        color: theme.palette.success.main,
        lineHeight: '40px',
        fontSize: '40px',
        [theme.breakpoints.down('sm')]: {
          lineHeight: '40px',
        },
        marginTop: theme.spacing(6),
      },
      '& p': {
        maxWidth: '690px',
        margin: theme.spacing(4, 'auto', 0),
        [theme.breakpoints.down('md')]: {
          margin: theme.spacing(2, 'auto', 0),
        },
      },
      '&  ul li': {
        textAlign: 'left',
        justifyContent: 'center',
      },
    },
    circle: {
      borderRadius: '999px',
      backgroundColor: theme.palette.type === 'dark' ? Colors.GreyFour : Colors.SecondaryDark,
      width: theme.spacing(3),
      height: theme.spacing(3),
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      textAlign: 'center',
      margin: theme.spacing(0, 2, 0, 0),
      flexShrink: 0,
      '& p': {
        color: theme.palette.type === 'dark' ? Colors.SecondaryDark : '#fff',
        textAlign: 'center',
        margin: '0 !important',
      },
      [theme.breakpoints.down('sm')]: {
        marginRight: theme.spacing(1.5),
      },
    },
    list: {
      margin: 0,
      padding: 0,
      listStyle: 'none',
      textAlign: 'left',
      width: '100%',
      [theme.breakpoints.down('sm')]: {
        maxWidth: '600px',
      },
      '& li': {
        margin: theme.spacing(0, 0, 3),
        padding: theme.spacing(0, 3, 0, 0),
        [theme.breakpoints.down('sm')]: {
          padding: '0 !important',
        },
      },
      '& h5': {
        margin: theme.spacing(2, 0, 3),
        color: theme.palette.success.main,
        fontWeight: 900,
        fontSize: '20px',
      },
      '& p': {
        marginBottom: theme.spacing(4),
        [theme.breakpoints.down('md')]: {
          marginBottom: theme.spacing(2),
        },
      },
    },
  }),
);
