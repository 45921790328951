import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import Img from 'gatsby-image';
import { Container, Typography } from '@material-ui/core';
import { useStyles } from './styles';

const Hero: React.FC = () => {
  const classes = useStyles();

  const data = useStaticQuery(graphql`
    query {
      image: file(relativePath: { eq: "new-jersey-solar-panel-installers.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1200) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `);

  return (
    <Container maxWidth="lg" className={classes.root}>
      <div className={classes.leftContainer}>
        <div className={classes.innerContainer}>
          <Typography color="primary" variant="h1">
          New Jersey<br /> Solar Panel Installers
          </Typography>

          <Typography variant="body1">
          Are you considering installing solar panels on your New Jersey home?
          </Typography>
          <Typography variant="body1">As more and more homeowners become aware of the incredible benefits of adopting solar energy, solar has become an increasingly popular alternative energy in the state of New Jersey. </Typography>
          <Typography variant="body1">
          New Jersey has traditionally been a solar sector pioneer. It is, in fact, one of just a few states in the United States with more than 1 gigawatt of installed solar capacity.
          </Typography>
        </div>
      </div>
      <div className={classes.rightContainer}>
        <Img fluid={data.image.childImageSharp.fluid} />
      </div>
    </Container>
  );
};

export default Hero;
