import React from 'react';
import { Container, Grid, Typography } from '@material-ui/core';
import { useStyles } from './styles';
import GetStartedButton from '../../../components/GetStartedButton';
import StartSavingWithSolar from '../StartSavingWithSolar';
import SolarIncentivesAndTaxCredits from '../SolarIncentivesAndTaxCredits';
import Questions from '../Questions';

const Content: React.FC = () => {
  const classes = useStyles();

  return (
    <Container component="section" maxWidth="lg" className={classes.root}>
      <div className={classes.copyContainer}>
        <Typography variant="h2">How We Install Solar Panels in New Jersey</Typography>
        <Typography variant="body1">
          We are already assisting homeowners in New Jersey making the switch to solar energy in three simple steps – at
          their own speed and convenience.
        </Typography>
      </div>

      <Grid className={classes.list} container component="ul" spacing={5}>
        <Grid item md={4} sm={12} component="li">
          <div className={classes.circle}>
            <Typography variant="body1">1</Typography>
          </div>
          <Typography variant="h5">Register to receive your free solar energy proposal</Typography>
          <Typography variant="body1">
            We collect satellite data and photos and use them in conjunction with our proprietary analytic techniques –
            established over decades in the business – to propose the best solutions based on available space and your
            actual energy usage.
          </Typography>
        </Grid>

        <Grid item md={4} sm={12} component="li">
          <div className={classes.circle}>
            <Typography variant="body1">2</Typography>
          </div>
          <Typography variant="h5">Analyze and select the best solar energy choice for you</Typography>
          <Typography variant="body1">
            Within 48-hours of making your purchase, your solar advocate will contact you about setting up your site
            survey evaluation.
          </Typography>
        </Grid>

        <Grid item md={4} sm={12} component="li">
          <div className={classes.circle}>
            <Typography variant="body1">3</Typography>
          </div>
          <Typography variant="h5">Solar Panel Installation and set up</Typography>
          <Typography variant="body1">
            Once you&apos;ve chosen the solar option that is appropriate for you, and you&apos;ve finished your purchase
            through our secure online gateway, Solvana will do the rest. Our staff will handle negotiating with your
            utility company for interconnection approvals, your local municipality for permitting approvals, turnkey
            installation, inspections, and even warranty claims - to make this process as simple as possible for you.
          </Typography>
        </Grid>
      </Grid>

      <div className={classes.copyContainer}>
        <Typography variant="h5">Are you ready to go solar in New Jersey?</Typography>
        <br></br>
        <div className={classes.copyContainer}>
          <GetStartedButton size="large" title="Click Here" />
        </div>
      </div>
      <div className={classes.copyContainer}>
        <StartSavingWithSolar />
        <SolarIncentivesAndTaxCredits />
        <Questions />
      </div>
    </Container>
  );
};

export default Content;
